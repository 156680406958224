import React from 'react';
import { LayoutProvider, SliceHeader } from '@lam-agency/toolkit/components';
import { useMVT } from '@lam-agency/toolkit/stores';
import { ImageGrid } from '@lam-agency/toolkit/components';
import {
  parseButtonDataFromFigma,
  parseImagesFromFigma
} from '@lam-agency/toolkit/utils/figma';

import * as styles from './styles.module.scss';
import { ISliceImageGrid } from 'lam-toolkit-figma-plugin';

const FigmaImageGrid = ({ buttonText, heading, images }: ISliceImageGrid) => {
  const { projectString } = useMVT();

  const imageGridItems = parseImagesFromFigma(projectString, images);
  const buttons = parseButtonDataFromFigma([buttonText]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LayoutProvider paddingX paddingY>
          <SliceHeader data={{ heading, buttons }} />

          {imageGridItems && <ImageGrid items={imageGridItems} />}
        </LayoutProvider>
      </div>
    </div>
  );
};

export default FigmaImageGrid;
