import React from 'react';
import {
  ColorTile,
  IntersectionAnimation
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { IColor } from 'lam-toolkit-figma-plugin';

interface IProps {
  colorRows: IColor[][];
}

const ColourInspector = ({ colorRows }: IProps) => {
  console.log(colorRows);
  return (
    <div className={styles.container}>
      {colorRows.map((row, i) => (
        <div className={styles.row} key={i}>
          {row.map(({ category, hex, id, name }) => (
            <IntersectionAnimation
              key={id}
              className={styles.cardContainer}
              animation="fadeGrow"
            >
              <ColorTile category={category} hex={hex} name={name} />
            </IntersectionAnimation>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ColourInspector;
