import React from 'react';
import {
  Imagery,
  LayoutProvider,
  SliceHeader,
  TextImageGrid,
  WebsitePrototype
} from '@lam-agency/toolkit/components';
import { useMVT } from '@lam-agency/toolkit/stores';
import {
  parseButtonDataFromFigma,
  parseImagesFromFigma
} from '@lam-agency/toolkit/utils/figma';

import * as styles from './styles.module.scss';
import { ISliceGallery } from 'lam-toolkit-figma-plugin';

const FigmaGallery = ({
  body1,
  body2,
  buttonText,
  heading,
  images
}: ISliceGallery) => {
  const { projectString } = useMVT();

  const imagery = parseImagesFromFigma(projectString, images);
  const buttons = parseButtonDataFromFigma([buttonText]);

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} data={{ heading, buttons }} />
      </LayoutProvider>

      {imagery?.[0]?.image && (
        <Imagery
          data={{
            image: imagery[0].image,
            button: { text: 'Download PNG', icon: 'download' }
          }}
        />
      )}

      {imagery?.[1]?.image && imagery?.[2]?.image && (
        <TextImageGrid
          body={body1}
          bodyPosition="right"
          items={[imagery[1], imagery[2]]}
        />
      )}

      <WebsitePrototype />

      {imagery?.[3]?.image && (
        <TextImageGrid body={body2} bodyPosition="left" items={[imagery[3]]} />
      )}
    </LayoutProvider>
  );
};

export default FigmaGallery;
