import React, { useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { Button, SVG, TSVG } from '../..';
import {
  copyImageToClipboardFromDataURL,
  copySVGToClipboardAsText,
  downloadImageFromDataURL,
  downloadSVGFromDataURL
} from '@lam-agency/toolkit/utils';
import { useApp } from '@lam-agency/toolkit/hooks';

interface IProps {
  colorScheme: 'dark' | 'light';
  logoDataUrl: string;
  className?: string;
  isUsingSVGLogo?: boolean;
}

type TActiveButtons = 'copy' | 'download' | null;

export const ImageCopyDownloadButtons = ({
  colorScheme,
  logoDataUrl,
  className,
  isUsingSVGLogo
}: IProps) => {
  const IMAGE_DOWNLOAD_FILENAME = 'logo';

  const [activeButtons, setActiveButtons] = useState<TActiveButtons>(null);

  const { updateNotification } = useApp();

  const isClipboardSupported = () => {
    if (typeof window !== 'undefined') {
      return typeof window.ClipboardItem !== 'undefined';
    }
    return false;
  };

  const handleCopyImageAsPNG = () => {
    copyImageToClipboardFromDataURL(logoDataUrl, updateNotification);
    setActiveButtons(null);
  };

  const handleCopySVGAsText = () => {
    copySVGToClipboardAsText(logoDataUrl, updateNotification);
    setActiveButtons(null);
  };

  const handleToggleCopy = () => {
    if (activeButtons === 'copy') {
      setActiveButtons(null);
    } else {
      setActiveButtons('copy');
    }
  };

  const handleToggleDownload = () => {
    if (activeButtons === 'download') {
      setActiveButtons(null);
    } else {
      setActiveButtons('download');
    }
  };

  const handleDownloadSVG = () => {
    downloadSVGFromDataURL(logoDataUrl, IMAGE_DOWNLOAD_FILENAME);
    setActiveButtons(null);
  };

  const handleDownloadPNG = () => {
    downloadImageFromDataURL(logoDataUrl, IMAGE_DOWNLOAD_FILENAME);
    setActiveButtons(null);
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.mainButtons}>
        {isClipboardSupported() && (
          <MainButton
            colorScheme={colorScheme}
            onClick={handleToggleCopy}
            svg="copy"
            active={activeButtons === 'copy'}
            inactive={activeButtons === 'download'}
          />
        )}

        <MainButton
          colorScheme={colorScheme}
          onClick={handleToggleDownload}
          svg="download"
          active={activeButtons === 'download'}
          inactive={activeButtons === 'copy'}
        />
      </div>

      {activeButtons && (
        <div className={styles.subButtons}>
          {activeButtons === 'copy' && (
            <>
              <SubButton
                onClick={handleCopyImageAsPNG}
                iconRight="copy"
                colorScheme={colorScheme}
              >
                Copy PNG
              </SubButton>

              {isUsingSVGLogo && (
                <SubButton
                  onClick={handleCopySVGAsText}
                  iconRight="copy"
                  colorScheme={colorScheme}
                >
                  Copy SVG Text
                </SubButton>
              )}
            </>
          )}

          {activeButtons === 'download' && (
            <>
              <SubButton
                onClick={handleDownloadPNG}
                iconRight="download"
                colorScheme={colorScheme}
              >
                Download PNG
              </SubButton>

              {isUsingSVGLogo && (
                <SubButton
                  onClick={handleDownloadSVG}
                  iconRight="download"
                  colorScheme={colorScheme}
                >
                  Download SVG
                </SubButton>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const MainButton = ({
  colorScheme,
  onClick,
  svg,
  active,
  inactive
}: {
  colorScheme: 'light' | 'dark';
  onClick: () => void;
  svg: TSVG;
  active: boolean;
  inactive: boolean;
}) => (
  <Button
    className={cn(styles.button, styles[colorScheme], {
      [styles.inactive]: inactive
    })}
    onClick={onClick}
  >
    <SVG
      className={cn(styles.buttonIcon, { [styles.active]: active })}
      svg={active ? 'x' : svg}
    />
  </Button>
);

const SubButton = ({
  iconRight,
  children,
  colorScheme,
  onClick
}: {
  iconRight: TSVG;
  children: React.ReactNode;
  colorScheme: 'light' | 'dark';
  onClick: () => void;
}) => (
  <div>
    <Button
      backgroundColor={colorScheme === 'light' ? 'black' : 'white'}
      color={colorScheme === 'light' ? 'white' : 'black'}
      iconRight={iconRight}
      className={styles[colorScheme]}
      onClick={onClick}
    >
      {children}
    </Button>
  </div>
);
