module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/packages/toolkit/src/common/layouts/index.tsx"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Referoo Brand ToolKit™","short_name":"Referoo Brand ToolKit™","description":"Referoo ToolKit™ is a dynamic digital tool, designed to make your brand more useable than ever.","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/assets/images/manifest-icon.png","include_favicon":false,"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"8162889c77d2c5b201cca447805f3aab"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-M0MBKEFPWV","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"() => {\n          const queryStrings =\n            (document && document.location && document.location.search) || {};\n\n          window.utms = queryStrings;\n\n          const queriesObj = queryStrings\n            ? Object.fromEntries(new URLSearchParams(queryStrings))\n            : {};\n\n          return {\n            platform: `gatsby`,\n            ...queriesObj\n          };\n        }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
