import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextImageGrid
} from '@lam-agency/toolkit/components';
import {
  parseButtonDataFromFigma,
  parseImagesFromFigma
} from '@lam-agency/toolkit/utils/figma';

import * as styles from './styles.module.scss';
import { useMVT } from '@lam-agency/toolkit/stores';
import { ISliceImageArticle } from 'lam-toolkit-figma-plugin';

const FigmaImageArticle = ({
  body,
  buttonText,
  heading,
  images
}: ISliceImageArticle) => {
  const { projectString } = useMVT();

  const imageGridItems = parseImagesFromFigma(projectString, images);
  const buttons = parseButtonDataFromFigma([buttonText]);

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} data={{ heading, buttons }} />
      </LayoutProvider>

      <TextImageGrid body={body} bodyPosition="right" items={imageGridItems} />
    </LayoutProvider>
  );
};

export default FigmaImageArticle;
