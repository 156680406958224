import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextImageGrid
} from '@lam-agency/toolkit/components';
import { useMVT } from '@lam-agency/toolkit/stores';
import {
  parseButtonDataFromFigma,
  parseImagesFromFigma
} from '@lam-agency/toolkit/utils/figma';

import * as styles from './styles.module.scss';
import { getCSSVariableValue } from '@lam-agency/toolkit/utils';
import { ISliceBorderRadius } from 'lam-toolkit-figma-plugin';

const FigmaBorderRadius = ({
  body,
  caption1,
  caption2,
  heading,
  buttonText,
  images
}: ISliceBorderRadius) => {
  const { projectString } = useMVT();

  const imageData = parseImagesFromFigma(projectString, images);
  const buttons = parseButtonDataFromFigma([buttonText]);

  const enableRoundedBorders =
    getCSSVariableValue('--border-radius-enable-round-corners-and-border') ===
    'true';

  const gridItems = [
    {
      image: imageData?.[0]?.image,
      button: {
        text: 'Copy CSS',
        url: '/',
        icon: 'copy'
      },
      caption: caption1,
      borderRadius: enableRoundedBorders
    },
    {
      image: imageData?.[1]?.image,
      button: {
        text: 'Copy CSS',
        url: '/',
        icon: 'copy'
      },
      caption: caption2,
      borderRadius: enableRoundedBorders
    }
  ];

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} data={{ heading, buttons }} />
      </LayoutProvider>

      <TextImageGrid body={body} bodyPosition="left" items={gridItems} />
    </LayoutProvider>
  );
};

export default FigmaBorderRadius;
