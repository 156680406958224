import React from 'react';
import cn from 'classnames';
import { Button, SVG, TSVG } from '@lam-agency/toolkit/components';
import { useApp } from '@lam-agency/toolkit/hooks';
import * as styles from './styles.module.scss';
import { copyImageToClipboardFromDataURL } from '@lam-agency/toolkit/utils';

export interface IImageWithCaption {
  image: string;
  button?: {
    text: string;
    url?: string;
    icon?: TSVG;
  };
  caption?: string;
  captionIcon?: TSVG;
  captionIconColor?: string;
  borderRadius?: boolean;
}

interface IProps {
  data: IImageWithCaption;
  showButtons?: boolean;
  className?: string;
}

const ImageWithCaption = ({
  data: { image, caption, borderRadius, captionIcon, captionIconColor },
  showButtons = false,
  className
}: IProps) => {
  const IMAGE_DOWNLOAD_WIDTH_PX = 1200;

  const { updateNotification } = useApp();

  if (!image) return null;

  const noCrop = image.endsWith('.png');

  const handleCopyImageAsPNG = async () => {
    copyImageToClipboardFromDataURL(
      image,
      updateNotification,
      IMAGE_DOWNLOAD_WIDTH_PX
    );
  };

  return (
    <div className={cn(styles.container, className, noCrop && styles.noCrop)}>
      <figure
        className={cn(styles.image, { [styles.borderRadius]: borderRadius })}
      >
        {/* todo - alt text */}
        <img src={image} />

        {/* todo: re-enable later */}
        {/* {button && (
          <Button className={styles.button} iconRight={button.icon}>
            {button.text}
          </Button>
        )} */}

        {showButtons && (
          <div className={styles.buttons}>
            <Button className={styles.button} onClick={handleCopyImageAsPNG}>
              <SVG className={styles.buttonIcon} svg="copy" />
            </Button>
            <Button className={styles.button} to={`${image}?download=`}>
              <SVG className={styles.buttonIcon} svg="download" />
            </Button>
          </div>
        )}
      </figure>

      {caption && (
        <div className={cn('caption', styles.caption)}>
          {captionIcon && (
            <figure
              className={styles.captionIcon}
              style={{ color: captionIconColor ? captionIconColor : 'inherit' }}
            >
              <SVG svg={captionIcon} />
            </figure>
          )}
          <p>{caption}</p>
        </div>
      )}
    </div>
  );
};

export default ImageWithCaption;
