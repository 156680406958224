import React from 'react';
import {
  LayoutProvider,
  ResourceCards,
  SliceHeader
} from '@lam-agency/toolkit/components';
import { ISliceLinksAndResources } from 'lam-toolkit-figma-plugin';

const FigmaLinksAndResources = ({
  heading,
  sections
}: ISliceLinksAndResources) => {
  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader data={{ heading, buttons: [] }} />

      {sections.map((section, i) => (
        <ResourceCards key={i} data={section} />
      ))}
    </LayoutProvider>
  );
};

export default FigmaLinksAndResources;
