import React from 'react';
import cn from 'classnames';
import { SVG } from '@lam-agency/toolkit/components';
import { Tag } from '@lam-agency/toolkit/components';
import {
  getTextThemeByBackgroundHex,
  hexToRGB
} from '@lam-agency/toolkit/utils';

import * as styles from './styles.module.scss';
import { useApp } from '@lam-agency/toolkit/hooks';

interface IProps {
  hex: string;
  name: string;
  category: string;
  className?: string;
}
export const ColorTile = ({ category, hex, name, className }: IProps) => {
  const { updateNotification } = useApp();
  const theme = getTextThemeByBackgroundHex(hex);

  const copyToClipboard = (value: string, valueType: string) => {
    navigator.clipboard.writeText(value);
    updateNotification(`Copied ${valueType} to clipboard`);
  };

  const { blue, green, red } = hexToRGB(hex);
  const rgba = `rgba(${red}, ${green}, ${blue}, 1)`;

  return (
    <article
      className={cn(styles.card, styles[theme], className)}
      style={{ background: `${hex}` }}
    >
      <header className={styles.cardHeader}>
        <button
          className={cn('b1', styles.copyButton)}
          onClick={() => copyToClipboard(name, 'name')}
        >
          {name}
          <SVG svg="copy" />
        </button>

        <Tag text={category} theme={theme} />
      </header>

      <div className={cn('caption', styles.cardBody)}>
        <button
          className={styles.copyButton}
          onClick={() => copyToClipboard(hex, 'hex')}
        >
          {hex}
          <SVG svg="copy" />
        </button>

        <button
          className={styles.copyButton}
          onClick={() => copyToClipboard(rgba, 'rgba')}
        >
          {rgba}
          <SVG svg="copy" />
        </button>
      </div>
    </article>
  );
};
