import React from 'react';
import {
  ColourInspector,
  LayoutProvider,
  LogoInspector,
  SliceHeader
} from '@lam-agency/toolkit/components';
import { parseButtonDataFromFigma } from '@lam-agency/toolkit/utils/figma';

import * as styles from './styles.module.scss';
import { ISliceStyleInspector } from 'lam-toolkit-figma-plugin';

const FigmaStyleInspector = ({
  buttonText1,
  buttonText2,
  heading1,
  heading2,
  logos,
  colorRows
}: ISliceStyleInspector) => {
  const buttonsLeft = parseButtonDataFromFigma([buttonText1]);
  const buttonsRight = parseButtonDataFromFigma([buttonText2]);

  //

  return (
    <LayoutProvider grid paddingX paddingY>
      <div className={styles.logos}>
        <SliceHeader
          className={styles.sliceHeader}
          data={{
            heading: heading1,
            buttons: buttonsLeft,
            alignButtons: 'left'
          }}
        />

        <LogoInspector logos={logos} />
      </div>

      <div className={styles.colours}>
        <SliceHeader
          className={styles.sliceHeader}
          data={{
            heading: heading2,
            buttons: buttonsRight,
            alignButtons: 'left'
          }}
        />
        <ColourInspector colorRows={colorRows} />
      </div>
    </LayoutProvider>
  );
};

export default FigmaStyleInspector;
